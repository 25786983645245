import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ContextProvider } from "./contexts/ContextProvider.jsx";
import { NotificationProvider } from "./contexts/NotificationContext.jsx";

import router from "./router.jsx";
import "./styles/app.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  // <React.StrictMode>
    <ContextProvider>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </ContextProvider>
  // </React.StrictMode>
);
